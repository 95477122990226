import { Navigate, useParams } from "react-router-dom";
import { handlePdfDownload } from "services";
import { ROUTES } from "variables";

const CODES = {
  "2je3bzQ": "https://www.cybercert.ai/en-au/ref/X7gH4vR9pZ",
  "3be3bzB":
    "https://cscau-public.s3.ap-southeast-2.amazonaws.com/CSCAU_CyberCert+Overview.pdf",
  "1ct7bU":
    "https://cscau-public.s3.ap-southeast-2.amazonaws.com/CyberCertPartnerProgram_Huntress.pdf",
  "6fkm9bx":
    "https://cscau-public.s3.ap-southeast-2.amazonaws.com/CyberCertPartnerProgram_SentinelOne.pdf",
  capmo:
    "https://cscau-public.s3.ap-southeast-2.amazonaws.com/docs/Cyber+Assurance+Program+(CAP)+-+Membership+v1.0.pdf",
  capfr:
    "https://cscau-public.s3.ap-southeast-2.amazonaws.com/docs/Cyber+Assurance+Program+(CAP)+-+Franhcises+V1.0.pdf",
  capsc:
    "https://cscau-public.s3.ap-southeast-2.amazonaws.com/docs/Supplier+Cyber+Assurance+Program+(SCAP)+V1.2.pdf",
  capmsp:
    "https://cscau-public.s3.ap-southeast-2.amazonaws.com/docs/Cyber+Assurance+Program+(CAP)+-+MSPs+V1.1.pdf",
};

export function QRPage() {
  const { code } = useParams();

  const redirect = CODES?.[code];

  if (redirect) {
    if (redirect.endsWith(".pdf")) {
      handlePdfDownload(
        redirect,
        redirect.split("/").pop().replace(/_/g, " ").replace(/\+/g, " "),
      );
    } else {
      window.location = redirect;
    }
  } else {
    return <Navigate to={ROUTES[404]} />;
  }

  return null;
}
