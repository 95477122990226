import { CAP_DOWNLOAD, CAP_LINK } from "variables";

/** GENERIC DOWNLOADS **/
export const handlePdfDownload = async (href, download) => {
  try {
    // Fetch the PDF file
    const response = await fetch(href);
    if (!response.ok) throw new Error("Network response was not ok");

    // Convert the response to a blob
    const blob = await response.blob();

    // Create a blob URL
    const blobUrl = URL.createObjectURL(blob);

    // Create an anchor and set properties
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = download;

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove added link
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("PDF Download Failed: ", error);
  }
};

/** SPECIFIC DOWNLOAD **/
export const downloadCAP = () => handlePdfDownload(CAP_LINK, CAP_DOWNLOAD);
