import { useEffect, useState } from "react";

import { FlowbiteSpinner } from "components";
import { useLocation } from "react-router-dom";
import { getPartnersMspDestination } from "services";

export function PartnersMspWrapper({ children }) {
  const [loading, setLoading] = useState(true);
  const location = useLocation;

  useEffect(() => {
    window.location.href = `${getPartnersMspDestination()}${location.search || ""}`;
    setLoading(false);
  }, []);

  return <>{loading ? <FlowbiteSpinner /> : <>{children}</>}</>;
}
