import { isDemoHost, isDevHost, isLocalHost, isProdHost } from "./environments";
import {
  CERTIFICATION_HOSTNAME,
  DASHBOARD_HOSTNAME,
  DEMO_CERTIFICATION_HOSTNAME,
  DEV_CERTIFICATION_HOSTNAME,
  DEV_DASHBOARD_HOSTNAME,
  DEV_LANDING_HOSTNAME,
  DEV_PARTNERS_MSP_HOSTNAME,
  LANDING_HOSTNAME,
  PARTNERS_MSP_HOSTNAME,
} from "./hostnames";

export const OFFICIAL_DOMAIN = "cybercert.ai";

export const LOCAL_LANDING = "http://localhost:3000";
export const LOCAL_DASHBOARD = "http://localhost:3001";
export const LOCAL_APP = "http://localhost:3002";
export const LOCAL_PARTNER_LANDING = "http://localhost:3003";

export const getLandingPage = () => {
  if (isLocalHost()) {
    return LOCAL_LANDING;
  } else if (isDevHost()) {
    return `https://${DEV_LANDING_HOSTNAME}`;
  } else if (isProdHost()) {
    return `https://${LANDING_HOSTNAME}`;
  }
};

export const getDashboardPage = () => {
  if (isLocalHost()) {
    return LOCAL_DASHBOARD;
  } else if (isDevHost()) {
    return `https://${DEV_DASHBOARD_HOSTNAME}`;
  } else if (isProdHost()) {
    return `https://${DASHBOARD_HOSTNAME}`;
  }
};

export const getAppPage = () => {
  if (isLocalHost()) {
    return LOCAL_APP;
  } else if (isDevHost()) {
    return `https://${DEV_CERTIFICATION_HOSTNAME}`;
  } else if (isProdHost()) {
    return `https://${CERTIFICATION_HOSTNAME}`;
  }
};

export const getAppDestination = (dashboard = true) => {
  if (isLocalHost()) {
    return dashboard ? LOCAL_DASHBOARD : LOCAL_APP;
  } else if (isDevHost()) {
    return dashboard
      ? `https://${DEV_DASHBOARD_HOSTNAME}`
      : `https://${DEV_CERTIFICATION_HOSTNAME}`;
  } else if (isProdHost()) {
    return dashboard
      ? `https://${DASHBOARD_HOSTNAME}`
      : `https://${CERTIFICATION_HOSTNAME}`;
  } else if (isDemoHost()) {
    return `https://${DEMO_CERTIFICATION_HOSTNAME}`;
  } else {
    return LOCAL_LANDING;
  }
};

/**
 * Get the landing destination
 */
export const getLandingDestination = () => {
  if (isDevHost()) {
    return `https://${DEV_LANDING_HOSTNAME}${window.location.pathname}`;
  } else if (isProdHost()) {
    return `https://${LANDING_HOSTNAME}${window.location.pathname}`;
  } else {
    return `${LOCAL_LANDING}${window.location.pathname}`;
  }
};

/**
 * Get the partners msp destination
 */
export const getPartnersMspDestination = () => {
  if (isDevHost()) {
    return `https://${DEV_PARTNERS_MSP_HOSTNAME}${getPartnersMspPathnameFromLanding(window.location.pathname)}`;
  } else if (isProdHost()) {
    return `https://${PARTNERS_MSP_HOSTNAME}${getPartnersMspPathnameFromLanding(window.location.pathname)}`;
  } else {
    return `${LOCAL_PARTNER_LANDING}${getPartnersMspPathnameFromLanding(window.location.pathname)}`;
  }
};

const getPartnersMspPathnameFromLanding = (path) => {
  // return path.replace("/partners/", "").replace("partner-", "");
  if (path.includes("/partners/msp")) {
    return path.replace("/partners/msp", "/msp");
  } else if (path.includes("/partner-signup")) {
    return path.replace("/partner-signup", "/signup");
  } else if (path.includes("/partner-form")) {
    return path.replace("/partner-form", "/signup");
  } else if (path.includes("/partner-register")) {
    return path.replace("/partner-register", "/register");
  } else if (path.includes("/partner-login")) {
    return path.replace("/partner-login", "/login");
  } else {
    return path;
  }
};
