import { WrapperItems } from "components";
import { PartnerFooter } from "layouts/footer";
import { Header } from "layouts/header";

export function LegalLayoutWrapper({ children }) {
  return (
    <>
      <WrapperItems />

      <Header />

      <div className="font-inter body-sm">{children}</div>

      <PartnerFooter />
    </>
  );
}
