import { createContext, useContext, useEffect, useState } from "react";

export const WindowSizeContext = createContext(null);

export function WindowSizeProvider({ children }) {
  const [windowSize, setWindowSize] = useState({
    wWidth: window.innerWidth,
    wHeight: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        wWidth: window.innerWidth,
        wHeight: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <WindowSizeContext.Provider value={windowSize}>
      {children}
    </WindowSizeContext.Provider>
  );
}

export function useWindowSize() {
  const context = useContext(WindowSizeContext);

  if (!context) {
    throw new Error("useWindowSize must be used within a WindowSizeProvider");
  }

  return context;
}
