import React, { useEffect, useState } from "react";

import { BsShieldFillCheck } from "react-icons/bs";
import { HiExclamationCircle, HiReply } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { openZEWidget } from "services";
import { ROUTES } from "variables";

export function FormCard({ title, confirmData, children }) {
  const location = useLocation();
  const [isPartnerLoginPage, setIsPartnerLoginPage] = useState(false);
  const [isResetPage, setIsResetPage] = useState(false);

  useEffect(() => {
    setIsPartnerLoginPage(
      location.pathname.split("/").includes("partner-login")
    );
    setIsResetPage(location.pathname.split("/").includes("reset"));
  }, [location]);

  return (
    <>
      <div className="form-container">
        <div className="flex w-full flex-col justify-between gap-8 tablet:gap-0">
          <div className="flex flex-col gap-8">
            <div className="title text-[38px] font-semibold text-black">
              {title}
            </div>
            {isPartnerLoginPage && (
              <span className="text-sm text-gray-600">
                Logging in as
                <span className="pl-1 font-medium text-blue-600">
                  <BsShieldFillCheck className="inline-block text-blue-600" />{" "}
                  Partner
                </span>
              </span>
            )}
          </div>
          <div className="flex w-full flex-col gap-4 laptop:w-[90%]">
            {!!confirmData && <div className="mt-10 mb-10">{confirmData}</div>}

            {isPartnerLoginPage ? (
              <p className="text-sm text-gray-700">
                This page is exclusively for Partners. If you want to join our
                Partner Program, we would love to hear from you!
              </p>
            ) : (
              <>
                {isResetPage ? (
                  <p className="text-sm text-gray-700">
                    Reset your password by following the instructions provided.
                  </p>
                ) : (
                  <p className="text-sm text-gray-700">
                    Complete a cyber security certification for your business in
                    just a few easy steps.
                  </p>
                )}
              </>
            )}

            <div className="text-[14px] text-gray-900 tablet:mb-1.5">
              {isPartnerLoginPage ? (
                <>
                  <HiExclamationCircle className="inline-block text-blue-600" />{" "}
                  <span
                    className="cursor-pointer font-medium text-blue-600 outline-none hover:underline focus:underline"
                    onClick={openZEWidget}
                    tabIndex={0}
                  >
                    Learn more about our CyberCert Assurance Program
                  </span>
                </>
              ) : (
                <>
                  {isResetPage ? (
                    <Link
                      to={ROUTES.LOGIN}
                      className="flex w-fit items-center gap-1 text-[14px] font-medium text-blue-600 hover:underline focus:underline"
                    >
                      Back to Login
                      <HiReply className="h-4 w-4" />
                    </Link>
                  ) : (
                    <Link
                      to={ROUTES.ROOT}
                      target="_blank"
                      className="flex w-fit items-center gap-1 text-[14px] font-medium text-blue-600 outline-none hover:underline focus:underline"
                    >
                      <HiExclamationCircle className="inline-block text-blue-600" />{" "}
                      Learn more about CyberCert
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="input-form flex w-full flex-col gap-4 tablet:mt-10">
          {children}
        </div>
      </div>
    </>
  );
}
