export const ROUTES = {
  SELF: {
    // for Route
    ROOT: "",
    LOGIN: "login",
    PARTNER_LOGIN: "partner-login",
    CLAIM: "claim",
    RESET: "reset",
    TERMS: "terms",
    PRIVACY_POLICY: "privacy-policy",
    COOKIE_POLICY: "cookie-policy",
    CPS: "SMB1001CPS.pdf",
    T2_INVITE: "invite/:token",
    PARTNER_OFFER: "offer/:token",
    PARTNER_INVITE: "partner-register",
    PARTNER_FORM: "partner-form",
    PARTNER_FORM_OLD: "partner-signup",
    PARTNER_FORM_REF: "ref/:code",
    MAINTENANCE: "maintenance",
    QR: "QR/:code",
    PARTNERS_MSP: "partners/msp/:referralCode?",
    TEST: "test",
  },
  PAGE_NOT_FOUND: "*",
  404: "/404",
};

Object.keys(ROUTES.SELF).forEach((k) => {
  // for navigate, Link
  ROUTES[k] = `/${ROUTES.SELF[k]}`;
});

export const DEFAULT_LANGUAGE = "en-au";
export const LANGUAGES = ["en-au", "fr-fr"];

export const LEVELS = {
  1: "Bronze",
  2: "Silver",
  3: "Gold",
  4: "Platinum",
  5: "Diamond",
};

export const LEVEL_NAMES = {
  Bronze: 0,
  Silver: 1,
  Gold: 2,
  Platinum: 3,
  Diamond: 4,
};

export const LEVEL_ANIMATIONS = {
  1: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/bronze-animated.webm",
  2: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/silver-animated.webm",
  3: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/gold-animated.webm",
  4: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/platinum-animated.webm",
  5: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/diamond-animated.webm",
};

export const LEVEL_ALL_ANIMATED =
  "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/spin-all-levels.mp4";

export const LEVEL_BACKGROUND_COLOURS = {
  1: "bg-bronze",
  2: "bg-silver",
  3: "bg-gold",
  4: "bg-platinum",
  5: "bg-diamond",
};

export const LEVEL_BADGES = {
  1: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/bronze-default.png",
  2: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/silver-default.png",
  3: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/gold-default.png",
  4: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/platinum-default.png",
  5: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/diamond-default.png",
};

export const LEVEL_DETAILS = {
  1: [
    "Access to the SMB1001 certification",
    "Implement 6 requirements",
    // "Perfect for micro and small businesses",
    "Self-Attested",
  ],
  2: [
    "Access to the SMB1001 certification",
    // "Perfect for micro and small businesses",
    "Implement 14 requirements",
    "Self-Attested",
  ],
  3: [
    "Access to the SMB1001 certification",
    // "Perfect for small and medium businesses",
    "Implement 22 requirements",
    "Self-Attested",
  ],
  4: [
    "Access to the SMB1001 certification",
    // "Perfect for small, medium and large businesses",
    "Implement 28 requirements",
    "Self-Attested + External Audit",
  ],
  5: [
    "Access to the SMB1001 certification",
    // "Perfect for medium and large businesses",
    "Implement 35 requirements",
    "Self-Attested + External Audit",
  ],
};

export const LEVEL_ICONS = {
  1: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/bronze-icon.png",
  2: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/silver-icon.png",
  3: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/gold-icon.png",
  4: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/platinum-icon.png",
  5: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/diamond-icon.png",
};

export const LEVEL_PRICES = {
  1: 95,
  2: 195,
  3: 395,
  4: "3,595",
  5: "5,995",
};

export const LEVEL_TEXT_COLOURS = {
  1: "text-bronze",
  2: "text-silver",
  3: "text-gold",
  4: "text-platinum",
  5: "text-diamond",
};

export const STANDARD_LINK = "https://www.cscau.com.au/smb1001";

export const CAP_LINK =
  "https://cscau-public.s3.ap-southeast-2.amazonaws.com/Cyber+Assurance+Program+(CAP)+-+MSPs+V1.1.pdf";
export const CAP_DOWNLOAD = "Cyber Assurance Program.pdf";
