import { CyberCertLogo, CyberCertLogoSecondary } from 'assets';
import { BsArrowUpCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import {
  openZEWidget,
  scrollToTop,
} from 'services';
import { ROUTES } from 'variables';

export function Footer() {
  return (
    <>
      <footer
        id="cybercert-footer"
        className="w-full bg-gray-900 text-sm font-normal !text-white"
      >
        <div
          className="w-full py-10 lg2:py-20 flex h-full flex-row items-center justify-between 2xl:px-0 px-5 lg2:px-[4%] 2xl:max-w-[1300px] mx-auto"
        >
          <div className="flex w-full flex-col gap-[50px] tablet:gap-[20px] laptop:gap-[60px]">
            <div className="flex flex-col items-start gap-[50px] tablet:flex-row tablet:justify-between laptop:gap-0">
              <div className="flex flex-col items-start gap-[30px]">
                <div className="rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-8 focus-within:outline-blue-800">
                  <CyberCertLogoSecondary
                    className="h-[27px] cursor-pointer outline-none"
                    onClick={scrollToTop}
                    tabIndex={0}
                  />
                </div>

                {/* <div className="flex flex-col">
                  <span>CyberCert Pty Ltd</span>
                  <span>ABN 87 662 681 423</span>
                </div> */}
                <div className="hidden lg:flex flex-col items-start gap-[10px] self-stretch !text-gray-500">
                  <div>© Copyright 2024, All rights reserved.</div>
                  <div>
                    The cybercert name, logo, and badges are trademarks.
                    <br />
                    Patents pending: 2023903514, 2023903509, 2023903507, 2023903506.
                  </div>
                </div>
              </div>

              <div className="flex w-full flex-col justify-between gap-[50px] tablet:w-fit tablet:flex-row tablet:justify-between tablet:gap-[60px]">
                <div className="flex w-[140px] flex-col items-start gap-4">
                  <span className="text-sm !text-gray-100">CONTACT US</span>
                  <span
                    className="text-sm cursor-pointer !text-gray-400 hover:!text-white"
                    onClick={openZEWidget}
                  >
                    Get in touch with us via our contact form.
                  </span>
                </div>

                {/* <div className="flex w-[150px] flex-col items-start gap-4">
                  <span className="text-sm text-gray-100">APAC</span>
                  <span className="text-gray-400">
                    GPO Box 1515 Brisbane, QLD 4001 Australia
                  </span>
                </div> */}

                <div className="flex w-[140px] flex-col items-start gap-4">
                  <span className="text-sm !text-gray-100">LEGAL</span>

                  <div className="flex flex-col items-start gap-2 ">
                    <div className="rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-4 focus-within:outline-blue-800">
                      <Link
                        to={ROUTES.TERMS}
                        className="outline-none !text-gray-400 hover:!text-white font-normal hover:underline"
                      >
                        Terms of Use
                      </Link>
                    </div>
                    <div className="rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-4 focus-within:outline-blue-800">
                      <Link
                        to={ROUTES.PRIVACY_POLICY}
                        className="outline-none !text-gray-400 hover:!text-white font-normal hover:underline"
                      >
                        Privacy Policy
                      </Link>
                    </div>
                    <div className="rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-4 focus-within:outline-blue-800">
                      <Link
                        to={ROUTES.COOKIE_POLICY}
                        className="outline-none !text-gray-400 hover:!text-white font-normal hover:underline"
                      >
                        Cookie Policy
                      </Link>
                    </div>
                    {/* <Link className="hover:underline">CPS</Link> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex lg:hidden flex-col items-start gap-[10px] self-stretch !text-gray-500">
              <div>© Copyright 2024, All rights reserved.</div>
              <div>
                The cybercert name, logo, and badges are trademarks.
                <br />
                Patents pending: 2023903514, 2023903509, 2023903507, 2023903506.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export function PartnerFooter() {
  return (
    <>
      <footer className="flex w-full flex-col items-center bg-gray-50 border-t border-gray-200">
        <div
          className="w-full py-10 lg2:py-20 flex h-full flex-row items-center justify-between 2xl:px-0 px-5 lg2:px-[4%] 2xl:max-w-[1300px] mx-auto"
        >
          <div className="flex w-full flex-col gap-[50px] lg2:gap-[20px] laptop:gap-[60px]">
            <div className="flex flex-col items-start gap-[50px] lg2:flex-row lg2:justify-between laptop:gap-0">
              <div className="flex flex-col items-start gap-[30px]">
                <div className="rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-8 focus-within:outline-blue-800">
                  <CyberCertLogo
                    className="h-[27px] cursor-pointer outline-none !text-gray-900"
                    onClick={scrollToTop}
                    tabIndex={0}
                  />
                </div>

                {/* <div className="flex flex-col text-gray-500">
                  <p className='body-sm font-normal'>CyberCert Pty Ltd</p>
                  <p className='body-sm'>ABN 87 662 681 423</p>
                </div> */}
                <div className="flex-col hidden xl:flex items-start gap-8 self-stretch !text-gray-400">
                  <p className='body-xs'>© Copyright 2024, All rights reserved.</p>
                  <p className='body-xs'>
                    The cybercert name, logo, and badges are trademarks.
                    <br />
                    Patents pending: 2023903514, 2023903509, 2023903507, 2023903506.
                  </p>
                </div>
              </div>

              <div className="flex w-full flex-col justify-between lg2:w-fit lg2:flex-row lg2:justify-between gap-14">
                <div className="flex w-full lg2:w-[150px] flex-col items-start gap-4">
                  <span className="body-sm !text-gray-900 font-semibold">CONTACT US</span>
                  <span
                    className="body-sm font-normal cursor-pointer !text-gray-500 hover:!text-gray-900"
                    onClick={openZEWidget}
                  >
                    Get in touch with us via our contact form.
                  </span>
                </div>

                <div className="flex w-fit flex-col items-start gap-4">
                  <span className="body-sm text-gray-900 font-semibold">LEGAL</span>

                  <div className="flex flex-col items-start gap-2 !text-gray-500">
                    <div className="rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-4 focus-within:outline-blue-800">
                      <Link
                        to={ROUTES.TERMS}
                        className="outline-none body-sm font-normal !text-gray-500 hover:!text-gray-900 hover:underline"
                      >
                        Terms of Use
                      </Link>
                    </div>
                    <div className="rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-4 focus-within:outline-blue-800">
                      <Link
                        to={ROUTES.PRIVACY_POLICY}
                        className="outline-none body-sm font-normal !text-gray-500 hover:!text-gray-900 hover:underline"
                      >
                        Privacy Policy
                      </Link>
                    </div>
                    <div className="rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-4 focus-within:outline-blue-800">
                      <Link
                        to={ROUTES.COOKIE_POLICY}
                        className="outline-none body-sm font-normal !text-gray-500 !hover:text-gray-900 hover:underline"
                      >
                        Cookie Policy
                      </Link>
                    </div>
                    {/* <Link className="hover:underline">CPS</Link> */}
                  </div>
                </div>

                <div className="flex w-fit flex-row items-start cursor-pointer" onClick={scrollToTop} >
                  <span className="text-sm font-semibold !text-[#1D4492] hover:!text-[#48A6CD] flex flex-row items-center gap-2">
                    Back to top
                    <BsArrowUpCircleFill className='w-4 h-4' />
                  </span>
                </div>
              </div>
            </div>

            <div className="flex xl:hidden flex-col items-start gap-[10px] self-stretch !text-gray-400">
              <p className='body-xs'>© Copyright 2024, All rights reserved.</p>
              <p className='body-xs'>
                The cybercert name, logo, and badges are trademarks.
                <br />
                Patents pending: 2023903514, 2023903509, 2023903507, 2023903506.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
