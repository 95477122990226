import { useEffect, useState } from "react";

import { FlowbiteSpinner, Select } from "components";
import { Table } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { RequirementsTableTheme } from "themes";
import { LEVEL_ICONS } from "variables";

const tableRowBg = {
  0: "bg-[#F3ECE6]",
  1: "bg-[#EEEFF0]",
  2: "bg-[#F4F1D3]",
  3: "bg-[#E5E4E2]",
  4: "bg-[#BFBFBF]",
};

const tableHeaderBg = {
  0: "!bg-[#ECD4C3]",
  1: "!bg-[#E1DBDC]",
  2: "!bg-[#EDDE98]",
  3: "!bg-[#C2C0BC]",
  4: "!bg-[#808080]",
};

const tableBadges = {
  0: (
    <img
      src={LEVEL_ICONS[1]}
      alt={"Bronze Badge Icon"}
      className="mx-auto h-[52px] w-[52px]"
    />
  ),
  1: (
    <img
      src={LEVEL_ICONS[2]}
      alt={"Silver Badge Icon"}
      className="mx-auto h-[52px] w-[52px]"
    />
  ),
  2: (
    <img
      src={LEVEL_ICONS[3]}
      alt={"Gold Badge Icon"}
      className="mx-auto h-[52px] w-[52px]"
    />
  ),
  3: (
    <img
      src={LEVEL_ICONS[4]}
      alt={"Platinum Badge Icon"}
      className="mx-auto h-[52px] w-[52px]"
    />
  ),
  4: (
    <img
      src={LEVEL_ICONS[5]}
      alt={"Diamond Badge Icon"}
      className="mx-auto h-[52px] w-[52px]"
    />
  ),
};

export function HomeRequirements({ reqs, prices, validMonths }) {
  const [certLevel, setCertLevel] = useState(0);
  const [tableReqs, setTableReqs] = useState(null);

  useEffect(() => {
    const removeDuplicateReqs = (reqs) => {
      const seenIds = new Set();
      let uniqueReqs = [];

      reqs.forEach((levelReqs, index) => {
        uniqueReqs[index] = levelReqs.filter((obj) => {
          if (!seenIds.has(obj.baseId)) {
            seenIds.add(obj.baseId);
            return true;
          }
          return false;
        });
      });

      return uniqueReqs.reduce((acc, arr) => acc.concat(arr), []);
    };
    if (reqs) {
      setTableReqs(removeDuplicateReqs(reqs));
    }
  }, [reqs]);

  return (
    <>
      {/*********************** Start of Mobile Table ***************************/}
      <div className="flex flex-col gap-[30px] self-stretch leading-normal tablet:hidden">
        {/* <div className="-mx-5">
          <Flowbite
            theme={{
              theme: {
                select: {
                  field: {
                    select: {
                      sizes: {
                        md: "bg-[length:200px_100px] text-[20px] font-bold",
                      },
                      withIcon: { on: "pl-12" },
                    },
                    icon: {
                      svg: "h-[30px] w-[30px] text-gray-500",
                    },
                  },
                },
              },
            }}
          >
            <Select id="cert-levels" onChange={selectCertLevel}>
              <option value={CertLevels.Bronze}>Bronze</option>
              <option data-content={BadgeSilver} value={CertLevels.Silver}>
                Silver
              </option>
              <option data-content={BadgeGold} value={CertLevels.Gold}>
                Gold
              </option>
            </Select>
          </Flowbite>
        </div> */}

        <Select
          className="-mx-5"
          options={[
            <>
              <img
                src={LEVEL_ICONS[1]}
                alt={"Bronze Badge Icon"}
                className="h-[30px] w-[30px]"
              />
              <div className="text-[24px] font-bold leading-normal">Bronze</div>
            </>,
            <>
              <img
                src={LEVEL_ICONS[2]}
                alt={"Silver Badge Icon"}
                className="h-[30px] w-[30px]"
              />
              <div className="text-[24px] font-bold leading-normal">Silver</div>
            </>,
            <>
              <img
                src={LEVEL_ICONS[3]}
                alt={"Gold Badge Icon"}
                className="h-[30px] w-[30px]"
              />
              <div className="text-[24px] font-bold leading-normal">Gold</div>
            </>,
            <>
              <img
                src={LEVEL_ICONS[4]}
                alt={"Platinum Badge Icon"}
                className="h-[30px] w-[30px]"
              />
              <div className="text-[24px] font-bold leading-normal">
                Platinum
              </div>
            </>,
            <>
              <img
                src={LEVEL_ICONS[5]}
                alt={"Diamond Badge Icon"}
                className="h-[30px] w-[30px]"
              />
              <div className="text-[24px] font-bold leading-normal">
                Diamond
              </div>
            </>,
          ]}
          value={certLevel}
          setValue={setCertLevel}
        />

        {reqs ? (
          <div className="-mx-5 flex flex-col self-stretch">
            <div className="bg-black p-[10px] text-xl font-semibold text-white">
              KEY DETAILS
            </div>

            <div className="flex flex-col px-[10px] py-5">
              <Detail
                title="Requirements to implement"
                value={reqs[certLevel]?.length}
              />
              <Detail
                title="Certification valid for"
                value={`${validMonths[certLevel]} months`}
              />
              <Detail
                title="Assurance level"
                value={
                  certLevel < 3
                    ? "Self-attested"
                    : "Self-attested + Externally Audited"
                }
              />
              <Detail title="Price" value={`$ ${prices[certLevel]}`} />
            </div>

            <div className="bg-black p-[10px] text-xl !font-semibold text-white">
              REQUIREMENTS
            </div>

            <ul className="py-5">
              {reqs[certLevel]?.map((item, index) => (
                <li
                  key={index}
                  className="flex p-[10px] text-sm text-gray-800 desktop:text-base"
                >
                  <div className="w-8 shrink-0">{index + 1}.</div>
                  <div>{item.title}</div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="text-center tablet:hidden">
            <FlowbiteSpinner />
          </div>
        )}
      </div>
      {/*********************** End of Mobile Table ***************************/}

      {/*********************** Start of Desktop Table ***************************/}
      {reqs ? (
        <div className="hidden tablet:block">
          <Table
            theme={RequirementsTableTheme}
            className="bg-alice-blue text-sm text-gray-800 desktop:text-base"
          >
            <Table.Head>
              <Table.HeadCell></Table.HeadCell>
              {Object.entries(tableBadges)?.map((item, index) => (
                <Table.HeadCell
                  key={index}
                  className={`${tableHeaderBg[index]} p-4`}
                >
                  {item[1]}
                </Table.HeadCell>
              ))}
            </Table.Head>

            <Table.Head>
              <Table.HeadCell>REQUIREMENTS</Table.HeadCell>
              {reqs?.map((item, index) => (
                <Table.HeadCell key={index} className="text-center font-medium">
                  {item?.length}
                </Table.HeadCell>
              ))}
            </Table.Head>

            <Table.Body>
              {tableReqs?.map((item, index) => {
                return (
                  <RequirementRow
                    key={index}
                    index={index}
                    reqs={reqs}
                    req={item}
                  />
                );
              })}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <div className="hidden tablet:flex tablet:justify-center">
          <FlowbiteSpinner />
        </div>
      )}
      {/*********************** End of Desktop Table ***************************/}
    </>
  );
}

function Detail({ title, value }) {
  return (
    <div className="flex flex-row justify-between">
      <div className="p-[10px] text-sm text-gray-800 desktop:text-base">
        {title}
      </div>

      <div className="p-[10px] text-right text-sm text-gray-800 desktop:text-base">
        {value}
      </div>
    </div>
  );
}

function RequirementRow({ index, reqs, req }) {
  return (
    <Table.Row>
      <Table.Cell>
        <div className="flex pr-[10px] text-sm font-normal text-gray-800 desktop:text-base">
          <div className="w-8 shrink-0">{index + 1}.</div>
          <div>{req.title}</div>
        </div>
      </Table.Cell>
      {reqs.map((item, index) => {
        return item.some((obj) => obj.baseId === req.baseId) ? (
          <Table.Cell key={index} className={`${tableRowBg[index]}`}>
            <HiCheck className="mx-auto h-5 w-5 text-green-500" />
          </Table.Cell>
        ) : (
          <Table.Cell key={index} />
        );
      })}
    </Table.Row>
  );
}
