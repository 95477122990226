import {
  useRef,
  useState,
} from 'react';

import { TextInput } from 'flowbite-react';
import { ensureHttps } from 'services';
import { TextInputTheme } from 'themes';

export function FlowbiteTextInput({ color = "primary", theme, ...props }) {
  const [isFocused, setIsFocused] = useState(false);

  const ref = useRef();

  const handleBlur = () => {
    setIsFocused(false);
    handleHttps();
  };
  const handleFocus = () => setIsFocused(true);

  const handleHttps = () => {
    if (ref?.current?.type === "url") {
      if (ref && ref.current) {
        ref.current.value = ensureHttps(ref.current.value);
      }
    }
  };

  return (
    <TextInput
      color={color}
      ref={ref}
      theme={{ ...TextInputTheme, ...theme }}
      onBlur={handleBlur}
      onFocus={handleFocus}
      {...props}
    />
  );
}
