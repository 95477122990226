import { useRef, useState } from "react";

import { ButtonWithRef, FlowbiteTextInput } from "components";
import {
  HiCheckCircle,
  HiOfficeBuilding,
  HiOutlineOfficeBuilding,
  HiRefresh,
} from "react-icons/hi";
import {
  filterNumberAndSpaceKeyPress,
  filterNumberAndSpacePaste,
  handleKeyDownSubmission,
  openZEWidget,
} from "services";

export function T2InviteFormSearchABN({
  registerState,
  handleInput,
  search,
  orgSearchRes,
  abnRegistered,
  abnSearchRes,
  validateContinue,
  canContinue,
  abnSearchErr,
  resetSearch,
  login,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const submitRef = useRef(null);

  const submitSearch = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    await search(registerState.abn);
    setIsLoading(false);
  };

  return (
    <div className="input-form flex flex-col gap-[16px]">
      <div className="flex flex-col items-start gap-[51px] self-stretch">
        <div className="flex flex-col gap-4 self-stretch">
          {abnRegistered ? (
            <>
              <div className="text-[14px] text-gray-600">
                This organisation has already been claimed
              </div>
              <div
                className={`lookup-details rounded border-[1px] border-${orgSearchRes?.certifiedStatus === -1 ? "yellow" : "red"}-600 bg-${orgSearchRes?.certifiedStatus === -1 ? "yellow" : "red"}-50 p-[20px]`}
              >
                <div className="mb-[16px] flex flex-row items-center justify-start gap-[16px]">
                  <HiOutlineOfficeBuilding className="font-bold text-gray-500" />{" "}
                  <span className="text-[14px] text-gray-600">
                    Organisation details
                  </span>
                </div>

                <div className="org-name mb-[12px] flex flex-row items-center justify-start gap-[8px]">
                  <span className="text-xl font-bold capitalize text-gray-900">
                    {abnSearchRes?.name}
                  </span>
                </div>

                <div className="org-abn">
                  <span className="font-bold">ABN</span> {abnSearchRes?.ABN}
                </div>

                <div className="org-location">
                  <span className="font-bold">Location</span>{" "}
                  {abnSearchRes?.addressState}, {abnSearchRes?.addressPostcode}
                </div>
              </div>

              <button
                className="flex w-fit cursor-pointer flex-row items-center gap-1 rounded-full text-blue-600 focus:underline enabled:hover:opacity-80"
                disabled={isLoading}
                onClick={resetSearch}
              >
                <span className="text-sm font-semibold ">Search Again</span>{" "}
                <HiRefresh />
              </button>
            </>
          ) : (
            <>
              {!!abnSearchRes ? (
                <>
                  <div className="text-sm text-gray-600">
                    Is this your organisation? Check the details and proceed
                  </div>

                  <div className="lookup-details rounded border-[1px] border-green-600 bg-green-50 p-[20px]">
                    <div className="mb-[16px] flex flex-row items-center justify-start gap-[16px]">
                      <HiOutlineOfficeBuilding className="font-bold text-gray-500" />{" "}
                      <span className="text-sm text-gray-600">
                        Organisation details
                      </span>
                    </div>

                    <div className="org-name mb-[12px] flex flex-row items-center justify-start gap-[8px]">
                      <span className="text-xl font-bold capitalize text-gray-900">
                        {abnSearchRes.name}
                      </span>{" "}
                      <HiCheckCircle className="h-5 w-5 text-green-500" />
                    </div>

                    <div className="org-abn">
                      <span className="font-bold">ABN</span> {abnSearchRes.ABN}
                    </div>

                    <div className="org-location">
                      <span className="font-bold">Location</span>{" "}
                      {abnSearchRes.addressState},{" "}
                      {abnSearchRes.addressPostcode}
                    </div>
                  </div>

                  <div>
                    <div className="text-[14px] text-gray-900">
                      If not,{" "}
                      <span
                        className="cursor-pointer font-medium text-blue-600 outline-none focus:underline enabled:hover:opacity-80"
                        onClick={resetSearch}
                        tabIndex={0}
                      >
                        Search Again
                      </span>{" "}
                      <HiRefresh className="inline-block text-blue-600" />
                    </div>

                    <div className="text-[14px] text-gray-900">
                      Cannot find your organisation or it's been claimed?{" "}
                      <span
                        onClick={openZEWidget}
                        className="cursor-pointer font-medium text-blue-600 outline-none focus:underline enabled:hover:opacity-80"
                        tabIndex={0}
                      >
                        {" "}
                        Get Help
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="subtitle text-[14px] leading-normal text-gray-600">
                    Enter the ABN for your organisation to start.
                  </div>

                  {!!abnSearchErr && (
                    <>
                      <div className="font-medium text-red-600">
                        ABN is invalid. Please try again.
                      </div>
                    </>
                  )}

                  <FlowbiteTextInput
                    id="ABN"
                    name="abn"
                    type="text"
                    icon={HiOfficeBuilding}
                    placeholder="Enter your ABN"
                    required
                    disabled={isLoading}
                    onChange={handleInput}
                    onKeyPress={filterNumberAndSpaceKeyPress}
                    onKeyDown={(event) =>
                      handleKeyDownSubmission(event, submitRef)
                    }
                    onPaste={filterNumberAndSpacePaste}
                    color={!!abnSearchErr ? "failure" : "gray"}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>

      {abnRegistered ? (
        <>
          <div className="text-[14px] text-gray-900">
            Cannot find your organisation or it's been claimed?{" "}
            <span
              onClick={openZEWidget}
              className="cursor-pointer font-medium text-blue-600 outline-none focus:underline enabled:hover:opacity-80"
              tabIndex={0}
            >
              {" "}
              Get Help
            </span>
            <div className="mt-8 flex flex-col gap-4">
              {orgSearchRes?.certifiedStatus === 0 && (
                <>
                  An account has been associated with this organisation, login
                  to your account instead.
                </>
              )}

              {orgSearchRes?.certifiedStatus === 1 && (
                <>
                  This account has already claimed a higher certification level
                  for {new Date().getFullYear()}. Login to your account to view
                  the certification.
                </>
              )}

              {orgSearchRes?.certifiedStatus === -1 && (
                <>
                  An account has been associated with this organisation, login
                  to your account to accept the invitation.
                </>
              )}

              <ButtonWithRef
                className="h-[35px] w-fit px-10 text-sm desktop:text-base"
                onClick={login}
              >
                Sign in
              </ButtonWithRef>
            </div>
          </div>
        </>
      ) : (
        <>
          {!!abnSearchRes ? (
            <div className="flex justify-end">
              <ButtonWithRef
                variant="small"
                ref={submitRef}
                type="submit"
                disabled={!canContinue || isLoading}
                onClick={validateContinue}
              >
                Continue
              </ButtonWithRef>
            </div>
          ) : (
            <div className="flex flex-col gap-4 tablet:flex-row tablet:items-center tablet:justify-between tablet:gap-0">
              <div className="flex gap-1 text-sm font-normal text-gray-900">
                Can’t find your ABN?
                <button
                  disabled={isLoading}
                  className="font-medium text-blue-600 focus:underline enabled:hover:opacity-80"
                  onClick={openZEWidget}
                >
                  Get Help
                </button>
              </div>

              <ButtonWithRef
                variant="small"
                ref={submitRef}
                type="submit"
                onClick={submitSearch}
              >
                Search ABN
              </ButtonWithRef>
            </div>
          )}
        </>
      )}
    </div>
  );
}
