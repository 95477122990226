import { useEffect, useRef, useState } from "react";

import { encode as base64_encode } from "base-64";
import { FormCard } from "components";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { AuthService, validateEmail } from "services";
import { ROUTES } from "variables";

import { ResetFormEmail } from "./reset-form-email";
import { ResetFormPassword } from "./reset-form-password";
import { ResetFormRequest } from "./reset-form-request";

const INITIAL_STATE = {
  email: "",
  code: "",
  tmpPassword: "",
  password: "",
  cPassword: "",
};

export function ResetForm({ step, setStep = () => {} }) {
  const { setLoading } = useLoadingState();
  const [resetState, setResetState] = useState(INITIAL_STATE);
  const pwdStrengthRef = useRef(null);
  const location = useLocation();

  const handleInput = (event) => {
    setResetState({
      ...resetState,
      [event.target.name]: event.target.value,
    });
  };

  const forgotPassword = async () => {
    setLoading(true);
    const { email } = resetState;
    let err;

    if (!email || !validateEmail(email)) {
      err = {
        type: "email",
        message: "Work email address is invalid.",
      };
    } else {
      try {
        await AuthService.forgotPassword(email, () => setStep("request"));
      } catch (error) {
        if (error.code === "UserNotFoundException") {
          err = {
            type: "forgotPassword",
            message:
              "Sorry, no account found associated with this email address.",
          };
        } else if (
          error.code === "InvalidParameterException" &&
          error.message.includes("no registered/verified")
        ) {
          console.log(`redirect`);
          window.location.href = `${ROUTES.ROOT}?c=${encodeURIComponent(
            base64_encode(
              JSON.stringify({
                confirm: true,
                email,
                resend: true,
              })
            )
          )}`;
        } else {
          err = {
            type: "forgotPassword",
            message: error.message,
          };
        }
      }
    }

    setLoading(false);
    return err;
  };

  const forgotPasswordConfirmReset = async () => {
    setLoading(true);
    const { email, code, password, cPassword } = resetState;
    let err;

    let pwdStrength;
    if (!!password) {
      pwdStrength = pwdStrengthRef?.current?.check(password);
    }

    if (!email || !validateEmail(email)) {
      err = { type: "email", message: "Work email address is invalid." };
    } else if (!code) {
      err = {
        type: "code",
        message: "Code is invalid.",
      };
    } else if (!password) {
      err = {
        type: "password",
        message: "Password is invalid.",
      };
    } else if (!cPassword || password !== cPassword) {
      err = {
        type: "match",
        message: "Passwords do not match.",
      };
    } else if (!pwdStrength?.accepted) {
      err = {
        type: "password",
        message: "Password needs to be at least 8 characters.",
      };
    } else {
      try {
        await AuthService.forgotPasswordConfirmReset(
          email,
          code,
          password,
          () =>
            (window.location.href = `${ROUTES.LOGIN}?c=${encodeURIComponent(
              base64_encode(
                JSON.stringify({
                  reset_success: true,
                })
              )
            )}`)
        );

        err = null;
      } catch (e) {
        err = {
          type: "forgotPassword",
          message: e.message,
        };
      }
    }

    setLoading(false);
    return err;
  };

  useEffect(() => {
    if (!!resetState?.password) {
      pwdStrengthRef?.current?.check(resetState?.password);
    }
    // else if (!!registerState?.cPassword) {
    //   pwdStrengthRef?.current?.check(registerState?.cPassword);
    // }
  }, [resetState?.password, resetState?.cPassword]);

  useEffect(() => {
    const params = queryString.parse(location.search);

    if (!!params.e && !!params.c) {
      setResetState((state) => ({
        ...state,
        email: params.e,
        code: params.c,
      }));
      setStep("password");
    } else if (!!params.e) {
      setResetState((state) => ({
        ...state,
        email: params.e,
      }));
    } else {
      setStep("email");
    }
  }, [location.search, setStep]);

  return (
    <FormCard title="Reset password">
      <div className="input-form flex flex-col gap-[16px]">
        {
          {
            email: (
              <ResetFormEmail
                handleInput={handleInput}
                resetState={resetState}
                proceedFn={forgotPassword}
              />
            ),
            request: (
              <ResetFormRequest state={resetState} resend={forgotPassword} />
            ),
            password: (
              <ResetFormPassword
                resetState={resetState}
                handleInput={handleInput}
                proceedFn={forgotPasswordConfirmReset}
                pwdStrengthRef={pwdStrengthRef}
              />
            ),
          }[step]
        }
      </div>
    </FormCard>
  );
}
