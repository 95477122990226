import { ErrorBanner, Navbar, PartnerOfferBanner } from "components";
import { usePartnerOffer } from "contexts";

export function Header() {
  const { partnerOffer } = usePartnerOffer();

  return (
    <div
      className={`fixed left-0 right-0 top-0 z-40 transition-all duration-[250ms]
                ${!!partnerOffer.ABN ? "h-[139px]" : "h-[75px]"}`}
    >
      <PartnerOfferBanner />
      <ErrorBanner />
      <Navbar />
    </div>
  );
}
