import { PageNotFoundImg } from "assets";
import { ButtonWithRef } from "components";
import { FaRegQuestionCircle } from "react-icons/fa";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { openZEWidget } from "services";
import { ROUTES } from "variables";

export function PageNotFound() {
  // redirect to login
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     window.location.href = ROUTES.LOGIN;
  //   }, 100);

  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      <section
        id="cybercert-hero"
        className="bg-pricing mx-auto flex w-full flex-grow self-stretch px-[20px] pt-[100px]"
      >
        <div className="home-hero min-h-[650px] items-center gap-[40px] tablet:flex-row tablet:gap-[20px] laptop:gap-[60px]">
          <div className="flex flex-col gap-[40px] tablet:min-w-[395px] laptop:min-w-[490px]">
            <div className="flex flex-col text-left text-[40px] font-semibold leading-tight tablet:text-[50px] laptop:text-[60px]">
              <h1 className="h1">Uh-oh!</h1>
            </div>

            <p className="p text-lg text-cyan-blue-medium-dark tablet:max-w-[400px] laptop:text-[20px]">
              Sorry, the page you are looking for does not exist or has been
              moved.
            </p>

            <div className="flex items-center gap-4">
              <Link to={ROUTES.ROOT}>
                <ButtonWithRef className="h-[40px] w-[190px] text-sm desktop:text-base">
                  Back to Home <HiArrowNarrowRight className="h-5 w-5" />
                </ButtonWithRef>
              </Link>
            </div>

            <div className="flex flex-col items-start gap-4">
              <Link
                onClick={openZEWidget}
                className="flex h-[40px] w-[190px] flex-row items-center gap-[10px] border-transparent text-base font-semibold hover:text-blue-600"
              >
                <FaRegQuestionCircle className="h-4 w-4" /> Get Help
              </Link>
              <p className="p text-base text-cyan-blue-medium-dark tablet:max-w-[450px]">
                Reach out to us anytime if you need assistance. Use the Help
                buttons in our site to get help.
              </p>
            </div>
          </div>

          {/* <img
            src={PageNotFoundImg}
            alt="Page not found"
            className="min-w-[250px] tablet:max-w-[610px] tablet:translate-y-[31px] tablet:overflow-hidden laptop:min-w-[610px] laptop:max-w-[800px] laptop:translate-y-[41px]"
          /> */}
          <PageNotFoundImg
            className="mx-auto w-full overflow-hidden tablet:max-w-[610px] 
          tablet:translate-y-[31px] laptop:min-w-[610px] laptop:max-w-[800px] laptop:translate-y-[41px]"
          />
        </div>
      </section>
    </>
  );
}
