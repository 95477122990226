import { Checkbox } from "flowbite-react";
import { CheckboxInputTheme } from "themes";

export function CheckboxInput({
  checked = false,
  color = "gray",
  id = "",
  name = "",
  onChange = () => {},
  onKeyDown = () => {},
}) {
  return (
    <Checkbox
      checked={checked}
      color={color}
      id={id}
      name={name}
      theme={CheckboxInputTheme}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
}
