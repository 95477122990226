export function LegalList({ children, className }) {
  return (
    <ul className={`mt-[20px] space-y-5 text-left ${className}`}>{children}</ul>
  );
}

export function LegalListItem({ children }) {
  return (
    <li className="body-sm flex items-start gap-4 space-x-1 align-top">
      <span className="mt-2 h-fit w-fit rounded-full bg-gray-300 p-0.5" />
      <span>{children}</span>
    </li>
  );
}

export function LegalSubList({ children, className }) {
  return (
    <ol className={`roman-list mt-5 space-y-2 text-left ${className}`}>
      {children}
    </ol>
  );
}

export function LegalListAlphabetical({ children, className }) {
  return (
    <ol className={`alphabet-list mt-5 space-y-2 text-left ${className}`}>
      {children}
    </ol>
  );
}

export function LegalSubListAlphabetical({ children, className }) {
  return (
    <ol className={`sub-alphabet-list mt-5 space-y-2 text-left ${className}`}>
      {children}
    </ol>
  );
}

export function LegalSubListItem({ children }) {
  return (
    <li className="space-x-3 align-top text-base text-gray-700">{children}</li>
  );
}

// export function LegalList({ children }) {
//   return (
//     <ul className="mt-[30px] flex flex-col gap-6 first:mt-0">{children}</ul>
//   );
// }

// export function LegalListItem({ children }) {
//   return (
//     <li className="flex text-base font-normal text-gray-700">
//       <span className="flex-shrink-0">
//         <BsFillCircleFill className="mr-[22px] mt-2 h-2 w-2 text-gray-300" />
//       </span>
//       <span className="flex-shrink">{children}</span>
//     </li>
//   );
// }
