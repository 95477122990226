import { LegalPageHeader } from '../page-header';

export function CookiePolicy() {
  return (
    <>
      <LegalPageHeader pageTitle={"Cookie Policy"} updateDate={"1 July 2023"} />

      <section className="legal-content -mt-[250px] mb-[80px] tablet:-mt-[180px] tablet:mb-[128px]">
        <div className="flex w-full flex-col gap-sm-c items-start gap-8 self-stretch rounded-lg border border-gray-200 bg-white p-8 shadow-sm">
          <p className="body-md font-semibold text-gray-900">
            The CyberCert Pty Ltd (CyberCert) website uses Google Analytics, a
            web analytics service provided by Google.
          </p>

          <p className='body-sm'>
            To analyse the usage of our website, Google Analytics uses cookies,
            a small piece of data that our website asks your browser to store on
            your PC, laptop, smartphone or another device. Cookies act like
            signposts, markers or tiny pieces of memory that enable us to give
            you the best experience possible when visiting our website.
          </p>

          <p className='body-sm'>
            Cookies are used to store information, such as the time that the you
            visited our website, whether you have been to our website before, or
            what site referred you to our website.
          </p>

          <p className='body-sm'>
            The information generated by the cookie about your use of our
            website (including your IP address) will be stored by Google in the
            United States. Google uses this information for the purpose of
            evaluating website usage and activity. Google will not associate
            your IP address with any other information held by Google.
          </p>

          <p className='body-sm'>
            You can prevent your information being used by Google Analytics,
            using Google Analytics Opt-out service,{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noreferrer"
              className="font-medium text-blue-600 outline-none hover:underline focus:underline"
            >
              here
            </a>
            . You may also choose to disable all cookies.
          </p>

          <p className='body-sm'>
            The use of cookies by CyberCert, however, supports your use of our
            website. Disabling our ability to deploy cookies may prevent you
            from using the website with ease.
          </p>

          <p className='body-sm'>
            If you wish to disable, prevent or clear all cookies from your
            browser, you can follow the instructions available on your web
            browser,{" "}
            <a
              href="https://www.allaboutcookies.org/verify"
              target="_blank"
              rel="noreferrer"
              className="font-medium text-blue-600 outline-none hover:underline focus:underline"
            >
              {" "}
              here{" "}
            </a>
            .
          </p>

          <p className='body-sm'>
            If you have any questions or concerns about our use of cookies,
            please contact us using our Contact Us Form available on our website
            (
            <a
              href="https://www.cybercert.ai"
              className="text-blue-600 outline-none hover:underline focus:underline"
            >
              cybercert.ai
            </a>
            ) , or via the details set out below.
          </p>



          <div className="flex flex-col gap-4 body-sm">
            <h4 className="h4 font-semibold text-black">Mail</h4>
            <span className="text-black">
              <span className='font-medium'>CyberCert Pty Ltd</span>
              <br />
              GPO Box 1515 <br />
              Brisbane, QLD 4001 <br />
              Australia
            </span>
          </div>

        </div>
      </section>
    </>
  );
}
