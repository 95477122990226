import { API } from "aws-amplify";

export const lookupABN = async ({ ABN }) => {
  let result;

  try {
    const myInit = {
      queryStringParameters: {
        ABN,
        op: "lookup",
        country: "au",
      },
    };

    result = await API.get("organisation", "/lookupOp", myInit);
  } catch (e) {
    console.error(e);
  }

  console.log(result);
  return result;
};

export const verifyLookupABN = async ({ ABN, checkoutId }) => {
  let result;

  try {
    const myInit = {
      queryStringParameters: {
        ABN,
        checkoutId,
        op: "verifyLookup",
        country: "au",
      },
    };

    result = await API.get("organisation", "/lookupOp", myInit);
  } catch (e) {
    console.error(e);
  }

  return result;
};
