import {
  useEffect,
  useState,
} from 'react';

import { Hub } from 'aws-amplify';
import { encode as base64_encode } from 'base-64';
import {
  FlowbiteSpinner,
  LoadingComponentOnload,
  LoadingScreen,
  LoadingScreenSpinner,
} from 'components';
import { PartnerOfferProvider } from 'contexts';
import { Flowbite } from 'flowbite-react';
import {
  HomeLayoutWrapper,
  LayoutWrapper,
  LegalLayoutWrapper,
  PartnersMspWrapper,
} from 'layouts';
import { useLoadingState } from 'middleware';
import {
  CookiePolicy,
  HomePage,
  Login,
  MaintenancePage,
  PageNotFound,
  PartnerFormRefPage,
  PrivacyPolicy,
  QRPage,
  Reset,
  T2Invite,
  Terms,
  TestPage,
} from 'pages';
import queryString from 'query-string';
import {
  Route,
  Routes,
} from 'react-router';
import {
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  AuthService,
  isDevHost,
  isLocalHost,
  openZEWidget,
  scrollToElementSmoothly,
} from 'services';
import { AlertTheme } from 'themes';
import {
  DEFAULT_LANGUAGE,
  LANGUAGES,
  ROUTES,
} from 'variables';

export function Router() {
  const { search, state } = useLocation();

  const { setLoading } = useLoadingState();

  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  useEffect(() => {
    setLoading(false);
    return Hub.listen("auth", ({ payload: { event, data } }) => {
      setLoading(true);
      switch (event) {
        case "customOAuthState":
          let oauthData;
          try {
            oauthData = JSON.parse(data);
          } catch (e) {
            console.error(e);
          }
          switch (oauthData?.name) {
            case "SSO_POST_SIGNUP":
              const { ABN, provider, userInvite, claimInfo } =
                oauthData.data || {};
              ssoPostSignup({ ABN, provider, userInvite, claimInfo });
              break;
            case "SSO_POST_SIGNIN":
              const { successUrl } = oauthData.data || {};
              AuthService.ssoPostSignin({ successUrl });
              // setLoadingMsg(
              //   "Sit tight while we prepare your account. It won't take long."
              // );
              break;
            default:
          }
          break;
        case "signOut":
        case "oAuthSignOut":
          try {
            let postSignOut = sessionStorage.getItem("POST_SIGNOUT");
            if (!!postSignOut) {
              sessionStorage.setItem("POST_SIGNOUT", "");
              sessionStorage.setItem("ACTION_SIGNIN", postSignOut);
            }
            window.location.reload();
          } catch (e) {
            console.error(e);
          }
          break;
        case "signIn":
        case "oAuthSignIn":
          // AuthService.postSignIn({});
          break;
        case "cognitoHostedUI":
        case "signIn_failure":
        case "cognitoHostedUI_failure":
        default:
      }
    });
  }, []);

  useEffect(() => {
    if (!!search) {
      const params = queryString.parse(search);
      if (params["zewidget"] === "1") {
        setTimeout(() => {
          openZEWidget();
        }, 1000);
      }
    }
  }, [search]);

  useEffect(() => {
    if (!!state?.hash) {
      scrollToElementSmoothly(state?.hash, 500);
    }
  }, [state]);

  useEffect(() => {
    try {
      let ssoSignIn = sessionStorage.getItem("ACTION_SIGNIN");
      if (!!ssoSignIn) {
        let { signInInput } = JSON.parse(ssoSignIn);
        sessionStorage.setItem("ACTION_SIGNIN", "");
        if (!!signInInput?.provider) {
          AuthService.signInWithSSO(signInInput);
        } else if (!!signInInput?.email && !!signInInput?.password) {
          AuthService.signIn(signInInput);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  async function ssoPostSignup(input = {}) {
    let { ABN, provider, userInvite, claimInfo } = input;
    setLoading(true);
    let result;
    if (!!userInvite?.invite) {
      result = await AuthService.postSignupWithSSOCheckUserInvite({
        ABN,
        provider,
        userInvite,
      });
    } else {
      result = await AuthService.postSignupWithSSO({ ABN, provider });
    }
    if (result?.error === "ACCOUNT_EXISTED") {
      window.location.href = `${ROUTES.ROOT}?c=${encodeURIComponent(
        base64_encode(
          JSON.stringify({
            ...claimInfo,
            error: "ACCOUNT_EXISTED",
          }),
        ),
      )}`;
    }
  }

  return (
    <>
      <div id="router-container" className="flex min-h-screen flex-col">
        <LoadingComponentOnload>
          <Flowbite theme={{ theme: { alert: AlertTheme } }}>
            <PartnerOfferProvider>
              {!!isMaintenanceMode ? (
                <Routes>
                  {/************************ MAINTENANCE MODE ************************/}
                  <Route
                    path={ROUTES.SELF.MAINTENANCE}
                    element={
                      <LayoutWrapper>
                        <MaintenancePage />
                      </LayoutWrapper>
                    }
                  />

                  <Route
                    path={ROUTES.PAGE_NOT_FOUND}
                    element={<Navigate to={ROUTES.SELF.MAINTENANCE} />}
                  />
                </Routes>
              ) : (
                <Routes>
                  {/************************** NORMAL MODE **************************/}
                  {LANGUAGES.map((LANG, index) => (
                    <Route key={index} path={LANG}>
                      <Route path={ROUTES.SELF.ROOT} element={<HomePage />} />

                      <Route
                        path={ROUTES.SELF.LOGIN}
                        element={
                          <LayoutWrapper>
                            <Login />
                          </LayoutWrapper>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.PARTNER_LOGIN}
                        element={
                          <PartnersMspWrapper>
                            <FlowbiteSpinner />
                          </PartnersMspWrapper>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.PARTNER_FORM_OLD}
                        element={
                          <PartnersMspWrapper>
                            <FlowbiteSpinner />
                          </PartnersMspWrapper>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.PARTNER_FORM}
                        element={
                          <PartnersMspWrapper>
                            <FlowbiteSpinner />
                          </PartnersMspWrapper>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.PARTNER_FORM_REF}
                        element={
                          <LayoutWrapper>
                            <PartnerFormRefPage />
                          </LayoutWrapper>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.RESET}
                        element={
                          <LayoutWrapper>
                            <Reset />
                          </LayoutWrapper>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.PARTNER_INVITE}
                        element={
                          <PartnersMspWrapper>
                            <FlowbiteSpinner />
                          </PartnersMspWrapper>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.T2_INVITE}
                        element={
                          <LayoutWrapper>
                            <T2Invite />
                          </LayoutWrapper>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.PARTNER_OFFER}
                        element={
                          <PartnersMspWrapper>
                            <FlowbiteSpinner />
                          </PartnersMspWrapper>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.TERMS}
                        element={
                          <LegalLayoutWrapper>
                            <Terms />
                          </LegalLayoutWrapper>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.PRIVACY_POLICY}
                        element={
                          <LegalLayoutWrapper>
                            <PrivacyPolicy />
                          </LegalLayoutWrapper>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.COOKIE_POLICY}
                        element={
                          <LegalLayoutWrapper>
                            <CookiePolicy />
                          </LegalLayoutWrapper>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.QR}
                        element={
                          <>
                            <LoadingScreenSpinner />
                            <QRPage />
                          </>
                        }
                      />

                      <Route
                        path={ROUTES.SELF.PARTNERS_MSP}
                        element={
                          <PartnersMspWrapper>
                            <FlowbiteSpinner />
                          </PartnersMspWrapper>
                        }
                      />

                      {(isLocalHost() || isDevHost()) && (
                        <Route
                          path={ROUTES.SELF.TEST}
                          element={
                            <HomeLayoutWrapper>
                              <TestPage />
                            </HomeLayoutWrapper>
                          }
                        />
                      )}

                      <Route
                        path={ROUTES.PAGE_NOT_FOUND}
                        element={
                          <HomeLayoutWrapper>
                            <PageNotFound />
                          </HomeLayoutWrapper>
                        }
                      />
                    </Route>
                  ))}

                  <Route
                    path={ROUTES[404]}
                    element={
                      <HomeLayoutWrapper>
                        <PageNotFound />
                      </HomeLayoutWrapper>
                    }
                  />

                  <Route
                    path={ROUTES.PAGE_NOT_FOUND}
                    element={<LanguageRedirect />}
                  />
                </Routes>
              )}
            </PartnerOfferProvider>
          </Flowbite>
        </LoadingComponentOnload>
      </div>
    </>
  );
}

function LanguageRedirect() {
  const { pathname, search, state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const lang = localStorage.getItem("LANG");

    // Skip static media files
    if (pathname.startsWith("/static/media/")) {
      return;
    }

    // Redirect to default language if no language in URL
    if (!lang) {
      localStorage.setItem("LANG", DEFAULT_LANGUAGE);
      navigate(`/${DEFAULT_LANGUAGE}${pathname}${search}`, {
        replace: true,
        state,
      });
    } else if (LANGUAGES.includes(lang)) {
      navigate(`/${lang}${pathname}${search}`, {
        replace: true,
        state,
      });
    }
  }, [pathname, search, state, navigate]);

  return (
    <>
      <LoadingScreen />
    </>
  );
}
