import { useErrorBanner } from "contexts";
import { HiExclamation } from "react-icons/hi";

export function ErrorBanner() {
  const { error } = useErrorBanner();

  if (!error) return null;
  return (
    <div className=" flex h-16 items-center justify-center gap-3 self-stretch bg-red-100 px-5 text-red-500 dark:bg-red-200 dark:text-red-600">
      <HiExclamation className="size-5" />

      <span className="text-xs font-semibold">{error}</span>

      {/* <button className="flex flex-shrink-0 items-center rounded-full">
        <HiX className="h-3 w-3" />
      </button> */}
    </div>
  );
}

//   hover:bg-red-200 focus:ring-red-400  dark:hover:bg-red-300
