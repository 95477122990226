import { createContext, useContext, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

export const ErrorBannerContext = createContext(null);

export function ErrorBannerProvider({ children }) {
  const [error, setError] = useState("");

  const location = useLocation();

  useEffect(() => {
    setError("");
  }, [location]);

  return (
    <ErrorBannerContext.Provider value={{ error, setError }}>
      {children}
    </ErrorBannerContext.Provider>
  );
}

export function useErrorBanner() {
  const context = useContext(ErrorBannerContext);

  if (!context) {
    throw new Error(
      "useErrorBanner must be used within an ErrorBannerProvider",
    );
  }

  return context;
}
