import { useEffect, useState } from "react";

import { ButtonWithRef } from "components";
import { FcGoogle } from "react-icons/fc";
import { HiLockClosed, HiUser } from "react-icons/hi";

// import { MicrosoftLogo } from "assets";

export function RegisterFormClaimLogin({
  registerState,
  invite,
  existingLogins,
  acceptInviteViaEmail,
  acceptInviteViaGG,
  acceptInviteViaRecover,
}) {
  const [err, setErr] = useState();

  return (
    <div className="input-form flex w-full flex-col gap-8 tablet:flex-row tablet:justify-between tablet:gap-32">
      <div className="flex w-full flex-col items-start gap-8">
        <div className="title text-[38px] font-semibold text-black">
          Sign in to your account
        </div>

        <div className="flex h-full flex-col justify-between gap-8 tablet:gap-16">
          <div className="flex flex-col gap-4">
            {!!invite && (
              <div className="flex flex-col gap-2">
                <div className="org-info">
                  <div className="mt-6 text-[14px] flex flex-col gap-2 text-gray-400">
                    <div className="flex flex-row gap-2">
                      <HiUser className="h-5 w-5" />
                      <span className="font-semibold text-gray-900">{registerState?.email}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex w-full flex-row items-start gap-8 tablet:w-[85%]">
            <div className="text-[14px] font-normal text-gray-600 leading-normal">
              Complete a cyber security certification for your business in just
              a few easy steps.
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 tablet:mt-10">
        <div className={`mt-[16px] flex flex-col gap-[8px] laptop:flex-row`}>
          <ButtonWithRef
            className="w-full"
            variant="social"
            onClick={acceptInviteViaEmail}
            disabled={!existingLogins?.includes("email")}
          >
            <HiLockClosed className="h-5 w-5" />
            Use your password
          </ButtonWithRef>
          <ButtonWithRef
            className="w-full"
            variant="social"
            onClick={acceptInviteViaGG}
            disabled={!existingLogins?.includes("google")}
          >
            <FcGoogle className="h-5 w-5" />
            Sign in with Google
          </ButtonWithRef>
        </div>

        <div className="flex flex-row justify-end">
          <button className="text-link" onClick={acceptInviteViaRecover}>
            Recover your account
          </button>
        </div>

        {!!err?.message && (
          <div className="text-[14px] font-medium text-red-600">
            {err.message}
          </div>
        )}
      </div>
    </div>
  );
}
