import { HiClock } from "react-icons/hi";

export function LegalPageHeader({ pageTitle, updateDate }) {
  return (
    <>
      {/* <section className="legal-page-bg"> */}
      <section className="bg-gray-100 h-[500px]">
        <section className="legal-content pt-[100px] tablet:pt-[150px]">
          <section>
            <div className="flex flex-col items-start gap-0.5 self-stretch px-[20px] tablet:px-0">
              <h2 className="h2 font-semibold text-black">{pageTitle}</h2>

              <div className="flex flex-row items-start lg:items-center gap-2 text-gray-800">
                <HiClock className="h-4 w-4 text-gray-400" />
                <div className="body-sm text-gray-500">
                  <span> Last updated:</span>{" "}
                  <span>{updateDate}</span>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
}
