import { WrapperItems } from "components";
import { Footer } from "layouts/footer";
import { Header } from "layouts/header";

export function HomeLayoutWrapper({ children }) {
  return (
    <>
      <WrapperItems />

      <Header />

      <main className="font-sohne flex min-h-screen flex-grow flex-col items-center justify-center">
        {children}
      </main>

      <Footer />
    </>
  );
}
