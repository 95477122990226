import React from "react";

import {
  HiCheckCircle,
  HiOutlineOfficeBuilding,
  HiRefresh,
} from "react-icons/hi";

export function ABNResult({ abnSearchRes, isError, resetSearch }) {
  return (
    <>
      <div className="text-[14px] text-gray-600">
        {isError
          ? "This organisation has already been claimed"
          : "Is this your organisation? Check the details and proceed"}
      </div>
      <div
        className={`lookup-details rounded p-[16px] ${
          isError
            ? "border border-red-600 bg-red-50"
            : "border border-green-600 bg-green-50"
        }`}
      >
        <div className="mb-[16px] flex flex-row items-center justify-start gap-[8px]">
          <HiOutlineOfficeBuilding className="font-bold text-gray-500" />
          <span className="text-[14px] text-gray-600">
            Organisation details
          </span>
        </div>

        <div className="org-name mb-[8px] flex flex-row items-center justify-start gap-[5px]">
          <span className="text-base font-semibold capitalize text-gray-900">
            {abnSearchRes?.name}
          </span>
          {isError ? null : (
            <HiCheckCircle className="h-5 w-5 text-green-500" />
          )}
        </div>

        <div className="org-abn text-sm">
          <span className="font-medium">ABN</span> {abnSearchRes?.ABN}
        </div>

        <div className="org-location text-sm">
          <span className="font-medium">Location</span>{" "}
          {abnSearchRes?.addressState}, {abnSearchRes?.addressPostcode}
        </div>
      </div>

      <div className="text-[14px] text-gray-900">
        {isError ? null : "If not, "}
        <span
          className="cursor-pointer font-medium text-blue-600 outline-none hover:underline focus:underline"
          onClick={resetSearch}
          tabIndex={0}
        >
          Search Again{" "}
          <HiRefresh className="inline-block text-blue-600 hover:underline focus:underline" />
        </span>
      </div>
    </>
  );
}
