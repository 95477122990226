import { ButtonWithRef } from "components/buttons";
import { usePartnerOffer } from "contexts";
// import { HiExternalLink } from "react-icons/hi";
import { Link } from "react-router-dom";
import { LEVEL_NAMES, STANDARD_LINK } from "variables";

export function CertCard({
  first = false,
  title,
  badge,
  subtitle,
  text,
  numReqs,
  certValid,
  assuranceLvl,
  price,
  onClick,
  tabIndex,
}) {
  const { partnerOffer } = usePartnerOffer();

  return (
    <div
      className={`
        relative flex w-full flex-col items-center gap-[30px] rounded-lg bg-white p-[30px] leading-normal shadow-xl ring-1 ring-inset
        ${first ? "ring-blue-gradient" : "ring-gray-300"}
      `}
    >
      <div className="flex w-full flex-col justify-center gap-5 laptop:gap-7">
        <div className="flex items-center gap-3">
          <div className="shrink-0">
            <img
              src={badge}
              alt={`badge-${title.toLowerCase()}`}
              className="max-h-[58px] max-w-[58px] laptop:max-h-[85px] laptop:max-w-[85px]"
            />
          </div>

          <div className="flex flex-col justify-center gap-[6px] self-stretch">
            <h4 className="h4 text-3xl font-bold text-gray-900 laptop:text-[40px]">
              {title}
            </h4>
            {/* <div className="text-sm text-gray-600">{subtitle}</div> */}
          </div>
        </div>

        <p className="p text-sm text-gray-600 desktop:text-base">{text}</p>
      </div>

      <div className="flex flex-col items-center gap-[30px] self-stretch text-sm laptop:gap-10 desktop:text-base">
        <div className="flex flex-col gap-[10px] self-stretch">
          <p className="p font-semibold text-black">Key Details</p>

          <div className="flex items-center justify-between border-b-[1px] border-gray-300 pb-[5px]">
            <p className="p text-gray-500 text-sm">Requirements to implement</p>
            <div className="text-right text-gray-800">{numReqs}</div>
          </div>

          <div className="flex items-center justify-between border-b-[1px] border-gray-300 pb-[5px]">
            <p className="p text-gray-500 text-sm">Certification valid for</p>
            <div className="text-right text-gray-800">{certValid}</div>
          </div>

          <div className="flex items-center justify-between border-b-[1px] border-gray-300 pb-[5px]">
            <p className="p text-gray-500 text-sm">Assurance</p>
            <div className="text-right text-gray-800">{assuranceLvl}</div>
          </div>

          <div className="flex items-center justify-between border-b-[1px] border-gray-300 pb-[5px]">
            <p className="p text-gray-500 text-sm">Standard</p>
            <Link
              to={STANDARD_LINK}
              target="_blank"
              className="text-right text-blue-600 outline-none hover:underline focus:underline"
              tabIndex={tabIndex}
            >
              SMB1001:2023 - Level {LEVEL_NAMES[title] + 1}
            </Link>
          </div>
        </div>

        <div className={`flex flex-col justify-center gap-5 self-stretch`}>
          <div className="flex flex-col self-stretch">
            <div
              className={`
                          h-[51px] -translate-y-1/4
                          laptop:-translate-y-1/2 laptop:text-[54px]
                          desktop:text-6xl
                        `}
            >
              <h4
                className={`h4 font-semibold flex w-full flex-row items-center gap-[8px] text-5xl ${!!partnerOffer?.discount ? "text-green-500" : "text-gray-900"}`}
              >
                {`$${!!partnerOffer?.discount ? (price * ((100 - partnerOffer.discount) / 100))?.toLocaleString() : price?.toLocaleString()}`}
                <p className="p text-base text-gray-500">plus GST</p>
              </h4>
            </div>

            {!!partnerOffer?.discount && (
              <div className="text-base font-normal">
                <p className="p text-gray">Original Price: </p>
                <span className="text-gray-500">{`$${price?.toLocaleString()}`}</span>
              </div>
            )}
          </div>

          <ButtonWithRef
            className={`h-10`}
            onClick={onClick}
            tabIndex={tabIndex}
          >
            Get Started
          </ButtonWithRef>
        </div>
      </div>

      {first && (
        <div
          className={`absolute left-1/2 top-[-11px] flex w-[164px] -translate-x-1/2 transform items-center justify-center rounded-[30px] bg-gradient-to-r from-blue-500 to-cyan-300 font-semibold`}
        >
          MOST POPULAR
        </div>
      )}
    </div>
  );
}

const verifiedBoxBg = {
  Diamond: {
    background:
      "linear-gradient(270deg, rgba(147, 147, 161, 0.85) 0%, rgba(189, 197, 204, 0.85) 35%, rgba(206, 207, 214, 0.85) 59%, rgba(166, 177, 184, 0.85) 83%, rgba(184, 188, 196, 0.85) 100%)",
  },
  Platinum: {
    background:
      "linear-gradient(270deg, #8E8E8E 0%, #FFF 45.31%, #8E8E8E 100%)",
  },
};

const cardBg = {
  Diamond: {
    background:
      "radial-gradient(190.57% 99.63% at 38.39% 16.14%, #343434 14.9%, #000 85.22%)",
  },
  Platinum: {
    background:
      "radial-gradient(190.57% 99.63% at 38.39% 16.14%, #454545 14.9%, #272727 85.22%)",
  },
};

const buttonBg = {
  Diamond: {
    background:
      "linear-gradient(270deg, rgba(147, 147, 161, 0.90) 0%, rgba(189, 197, 204, 0.90) 35%, rgba(206, 207, 214, 0.90) 59%, rgba(166, 177, 184, 0.90) 83%, rgba(184, 188, 196, 0.90) 100%)",
  },
  Platinum: {
    background:
      "linear-gradient(270deg, #8E8E8E 0%, #FFF 45.31%, #8E8E8E 100%)",
  },
};

export function EliteCertCard({
  first = false,
  title,
  badge,
  subtitle,
  text,
  numReqs,
  certValid,
  assuranceLvl,
  price,
  onClick,
  onAssuranceClick,
  tabIndex,
}) {
  const { partnerOffer } = usePartnerOffer();

  return (
    <div
      className={`
        relative flex w-full flex-col items-center gap-[30px] rounded-lg p-[30px] leading-normal shadow-xl ring-1 ring-inset
        tablet:justify-between
        ${first ? "ring-blue-gradient" : "ring-gray-300"}
      `}
      style={cardBg[title]}
    >
      <div className={`flex w-full flex-col justify-center gap-5 laptop:gap-7`}>
        <div
          className={`flex flex-col-reverse justify-between gap-2 tablet:flex-row`}
        >
          <div className="flex flex-row gap-3">
            <div className={`shrink-0`}>
              <img
                src={badge}
                alt={`badge-${title.toLowerCase()}`}
                className="max-h-[58px] max-w-[58px] laptop:max-h-[85px] laptop:max-w-[85px]"
              />
            </div>

            <div
              className={`flex flex-col justify-center gap-[6px] self-stretch`}
            >
              <h4
                className={`h4 text-3xl font-bold text-gray-50 laptop:text-[40px]`}
              >
                {title}
              </h4>
              {/* <div className={`text-sm text-gray-600`}>{subtitle}</div> */}
            </div>
          </div>

          <div className="flex w-full flex-row justify-end">
            <div
              className={`flex h-[21px] w-[78px] items-center justify-center rounded-lg px-[7px] py-0`}
              style={verifiedBoxBg[title]}
            >
              <span className={`text-sm font-bold leading-[20px]`}>
                VERIFIED
              </span>
            </div>
          </div>
        </div>

        <p className="p text-sm text-gray-300 desktop:text-base">{text}</p>
      </div>

      <div className="flex flex-col items-center gap-[30px] self-stretch text-sm laptop:gap-10 desktop:text-base">
        <div className="flex flex-col gap-[10px] self-stretch">
          <p className="p font-semibold text-white">Key Details</p>

          <div className="flex items-center justify-between border-b-[1px] border-zinc-700 pb-[5px]">
            <p className="p text-gray-300 text-sm">Requirements to implement</p>
            <div className="text-right text-gray-300">{numReqs}</div>
          </div>

          <div className="flex items-center justify-between border-b-[1px] border-zinc-700 pb-[5px]">
            <p className="p text-gray-300 text-sm">Certification valid for</p>
            <div className="text-right text-gray-300">{certValid}</div>
          </div>

          <div className="flex items-center justify-between border-b-[1px] border-zinc-700 pb-[5px]">
            <p className="p text-gray-300 text-sm">Assurance</p>
            <button
              className="cursor-pointer text-right text-blue-600 outline-none hover:underline focus:underline"
              onClick={onAssuranceClick}
              tabIndex={tabIndex}
            >
              {assuranceLvl}
            </button>
          </div>

          <div className="flex items-center justify-between border-b-[1px] border-zinc-700 pb-[5px]">
            <p className="p text-gray-300 text-sm">Standard</p>
            <Link
              to={STANDARD_LINK}
              target="_blank"
              className="text-right text-blue-600 outline-none hover:underline focus:underline"
              tabIndex={tabIndex}
            >
              <div className={`flex items-center gap-1`}>
                SMB1001:2023 - Level {LEVEL_NAMES[title] + 1}
                {/* <HiExternalLink className={`h-4 w-4`} /> */}
              </div>
            </Link>
          </div>
        </div>

        <div className="flex flex-col justify-center gap-5 self-stretch">
          <div className="flex flex-col self-stretch">
            <div
              className={`
                        h-[51px] -translate-y-1/4
                        laptop:-translate-y-1/2 laptop:text-[54px]
                        desktop:text-6xl
                      `}
            >
              <h4
                className={`h4 flex w-full flex-row items-center gap-[8px] text-5xl font-semibold ${!!partnerOffer?.discount ? "text-green-300" : "text-white"}`}
              >
                {`$${!!partnerOffer?.discount ? (price * ((100 - partnerOffer.discount) / 100))?.toLocaleString() : price?.toLocaleString()}`}
                <span className="text-base text-gray-300">plus GST</span>
              </h4>
            </div>

            {!!partnerOffer?.discount && (
              <div className="text-base font-normal">
                <span className="text-gray-300">Original Price: </span>
                <span className="text-white">{`$${price?.toLocaleString()}`}</span>
              </div>
            )}
          </div>

          <ButtonWithRef
            variant="blank"
            className="h-10 text-sm font-semibold text-gray-900 tablet:text-base"
            style={buttonBg[title]}
            onClick={onClick}
            tabIndex={tabIndex}
          >
            Contact Us to Get Started
          </ButtonWithRef>
        </div>
      </div>

      {first && (
        <div className="absolute left-1/2 top-[-11px] flex w-[164px] -translate-x-1/2 transform items-center justify-center rounded-[30px] bg-gradient-to-r from-blue-500 to-cyan-300 font-semibold">
          MOST POPULAR
        </div>
      )}
    </div>
  );
}
